@media only screen and (max-width: 640px) {
  .ReactModal__Content {
    inset: 10px !important;
    padding: 10px !important;
    input {
      max-width: 150px;
      margin-left: auto;
    }
  }
}
