@import "react-big-calendar/lib/sass/styles";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --heading-light: #ffffff;
  // --heading-light: #d6cedd;
  --back-drop-light: #d6d6d6;
  --btn-background-dark: #919ba2;
  --logo-blue: #00adee;
  --logo-purple: #865984;
  --link-blue: #0060df;
  --logo-purple-comp: #79a67b;
  --logo-blue-comp: #ff5211;
  --tw-text-opacity: 1;
  --footer-height: 1.5rem;
  --header-height: 50px;
  @media only screen and (min-width: 600px) {
    --header-height: 0px;
  }
  --body-container-height: calc(
    var(--vh, 1vh) * 100 - var(--header-height) - var(--footer-height) - 8px
  );
  --body-container-height: calc(
    100% - var(--header-height) - var(--footer-height) - 8px
  );
}
* {
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow: hidden;
  max-height: 100vh;
  height: 100vh;
  position: relative;
}
html {
  font-size: 14px;
}
a {
  &:hover {
    color: var(--link-blue);
    text-decoration: underline;
  }
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    align-items: center;
    margin: 0;
  }
}
dl {
  padding: 0 0 0 15px;
  dt {
    font-weight: bold;
  }
  dd {
    margin-left: 5px;
  }
}
abbr {
  border-bottom: none;
}
tr,
td {
  margin: 0;
  padding: 0;
}
button {
  cursor: pointer;
}
button:disabled,
input:disabled {
  cursor: not-allowed;
}

.basic-input input,
.select select:not(.inventory-input) {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  // cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 36px;
  line-height: 24px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 2px 8px;
  width: 100%;
  transition: width 0ms;
  &:focus {
    border-color: #2684ff;
    border-width: 2px;
  }
}
input[type="checkbox"]:hover + div,
input[type="checkbox"]:focus + div {
  --tw-shadow: inset 0 0 1px 0px #2684ff;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #2684ff),
    var(--tw-ring-shadow, 0 0 #2684ff), var(--tw-shadow);
}
.inventory-input {
  max-width: 100%;
  width: 100%;
  // height: 2rem;
  line-height: inherit;
  box-shadow: 0 0 0px 1px #c2c2c2;
  min-width: 60px;
  background: #fff;
  border-top-style: inset;
  border-block-start-style: inset;
  border-collapse: separate;
  border-inline-start-width: 8px;
  border-inline-end-width: 8px;
  border: 0px solid #fff0;
  border-radius: 3px;
  -moz-tab-size: 8;
  tab-size: 8;
  border-left-width: 8px;
  border-right-width: 8px;
}
.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: table;
  width: inherit;
}
.select:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='black' fill='black' stroke-width='1' viewBox='0 0 448 512' height='1em' width='1em'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  height: 1em;
  width: 1.2em;
  right: 7px;
  /*Adjust for position however you want*/
  bottom: 0.5em;
  padding: 0 0 2px;
  /*left line */
  position: absolute;
  pointer-events: none;
}
@media only screen and (max-width: 640px) {
  .scroll- {
    // margin for item container headers
    &margin-IC {
      scroll-margin-top: 58px;
      &.edit {
        scroll-margin-top: 43px;
      }
    }
    &margin-DI {
      scroll-margin-top: calc(7ch + 12px);
    }
    &margin-PR {
      scroll-margin-left: calc(3px);
    }
  }
}
// input[type="checkbox"] {
//   box-shadow: none;
//   min-width: 1rem;
//   min-height: 1rem;
// }
.ReactModal__Overlay {
  z-index: 100;
  background-color: #dededecc !important;
  .ReactModal__Content {
    box-shadow: 5px 5px 5px #777;
  }
}

.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}
.overflow-scroll {
  overflow: scroll;
}
.overflowX-scroll {
  overflow-x: scroll;
}
.overflowY-scroll {
  overflow-y: scroll;
}
.overflowX-hidden {
  overflow-x: hidden;
}
.overflowY-hidden {
  overflow-y: hidden;
}
.pointer {
  cursor: pointer;
}
.z- {
  &1 {
    z-index: 1;
  }
  &2 {
    z-index: 2;
  }
  &3 {
    z-index: 3;
  }
  &4 {
    z-index: 4;
  }
  &5 {
    z-index: 5;
  }
  &6 {
    z-index: 6;
  }
  &7 {
    z-index: 7;
  }
}
#root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.App {
  position: relative;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.wrapper {
  min-height: 100%;
  height: 100%;
  display: grid;
  grid-template: auto 1fr auto / auto 1fr auto;
}
.header-wrapper {
  grid-column: 1 / 4;
}
.body-container {
  overflow: hidden;
  height: 100%;
  grid-column: 2 / 3;
  // height: var(--body-container-height);
  max-width: calc(100vw - 2rem - 4px);
  position: relative;
}
.bg {
  &-back-drop-light {
    background-color: var(--back-drop-light);
  }
  &__logo-blue {
    background-color: var(--logo-blue);
  }
}
.side-bar {
  width: 100%;
  // @media only screen and (min-width: 600px) {
  &-container {
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
    &-logo {
      padding-top: 45.5%;
      height: 100%;
    }
    &-avatar {
      padding-top: calc(100% - 20px);
    }
    img {
      display: block;
      // height: 100%;
      position: absolute;
      top: 0;
      left: 4%;
    }
    // }
    &__left {
      width: 200px;
      transition: width 500ms, margin 750ms, transform 750ms;
      overflow-x: hidden;
      li {
        a {
          text-overflow: clip;
          white-space: nowrap;
          z-index: 5;
        }
      }
      &.hide-side-bar {
        width: 1em;
        transform: translateX(-10em);
      }
    }
    &__logo {
      margin-top: 4%;
      // height: 100%;
    }
    &__avatar {
      margin: 10px 0 0 0;
    }
    &__loading {
      width: 100%;
      height: 15px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }
  &__right {
    grid-column: 3/4;
  }
}
.footer-wrapper {
  height: var(--footer-height);
  grid-column: 1 / 4;
}
.sticky {
  &.top {
    top: 0;
    &__1px {
      // use top-px now
      top: 1px;
    }
  }
}
.absolute {
  &__zero {
    top: 0;
    right: 0;
    left: 0;
  }
  &.top {
    top: 0;
  }
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
  &.bottom {
    bottom: 0;
  }
}
.w-100p {
  width: 100%;
}
.w-75p {
  width: 75%;
  &-min {
    min-width: 75%;
  }
}
.w-1\/2 {
  width: 50%;
}
.hundred-hundred {
  height: 100%;
  width: 100%;
  &__min {
    min-height: 100%;
    min-width: 100%;
  }
}
.w {
  &-16 {
    width: 4rem;
  }
  &-48 {
    width: 12rem;
  }
}
.height-panel {
  height: 95vh;
}
.height-10p {
  height: 10%;
}
.flex {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.column {
    flex-direction: column;
  }
  &-grow {
    flex-grow: 1;
  }
  &-row-wrap {
    flex-flow: row wrap;
  }
}
.sup-dollar:before {
  content: "$";
  font-size: 65%;
  vertical-align: super;
}
.grid {
  &.center {
    place-items: center;
    &-right {
      justify-content: right;
    }
  }
  .align-center {
    align-self: center;
  }

  .place-center {
    place-self: center;
  }
  &.auto-1fr {
    grid-template-columns: auto 1fr;
  }
  &.fr-auto {
    grid-template-columns: 1fr auto;
  }
  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
    &-auto {
      grid-template-columns: repeat(2, auto);
    }
  }
  &.col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.col-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  &.col-6 {
    grid-template-columns: repeat(6, 1fr);
  }
  &.col-7 {
    grid-template-columns: repeat(7, 1fr);
  }
  &.col-8 {
    grid-template-columns: repeat(8, 1fr);
  }
  &.row-3 {
    grid-template-rows: repeat(3, 1fr);
  }
  &__center {
    place-items: center;
  }
  .row-1 {
    grid-row: 1/2;
  }
  .rows-2 {
    grid-row: 1/3;
  }
  .column-2 {
    grid-column: 2/3;
  }
  .columns-1to3 {
    grid-column: 1/3;
  }
  &.gap-1 {
    gap: 1rem;
  }
  &.gap-2 {
    gap: 2rem;
  }
  .full-col-span {
    grid-column: 1/-1;
  }
  .full-row-span {
    grid-row: 1/-1;
  }
}

.red {
  background-color: red;
}

.simple-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  &.mobile-column {
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
}
.grid-center {
  display: grid;
  place-items: center;
}
.simple-border {
  // margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  &__no-margin {
    margin: 0;
  }
  @media only screen and (max-width: 600px) {
    &__mobile-hide {
      border: none;
    }
  }
}
.simple-border-pad {
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 0px;
}
.simple-outline-pad {
  padding: 8px;
  outline: 1px solid lightgrey;
  border-radius: 0px;
}

.main-column {
  height: 95vh;
}

@media only screen and (max-width: 600px) {
  .mobile {
    &__vertical-margin-none {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &__col-1 {
      grid-template-columns: 1fr !important;
    }
    &__gap-1 {
      gap: 1em !important;
    }
  }
  .mobile-hide {
    display: none !important;
  }
}
.split-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .right {
    text-align: right;
  }
}
.text-right {
  text-align: right;
}

.font-size {
  &__125em {
    font-size: 1.25em;
  }
}
.link {
  cursor: pointer;
  color: var(--link-blue);
  text-decoration: underline;
}
.dark-hover:hover {
  filter: brightness(80%);
}
.btn {
  text-align: center;
  transition: background-color 200ms, color 300ms;
  font-weight: 400;
  min-height: 38px;
  display: grid;
  place-items: center;
  cursor: pointer;
  &-disabled {
    color: #555 !important;
    background-color: #eee !important;
    cursor: default;
    text-decoration: none !important;
  }
  &-defaults {
    border: 1px solid #646079;
    width: 100%;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &-confirm {
    background-color: var(--logo-blue);
    color: black;
    &:disabled {
      filter: hue-rotate(180deg);
    }
  }
  &-staging-builder {
    border: 1px solid #646079;
    color: white;
    background-color: var(--logo-purple);
    width: 100%;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    a {
      color: white;
      text-decoration: none;
      transition: color 200ms;
    }
    &:hover {
      background-color: var(--logo-blue);
      color: black;
      a {
        color: black;
      }
    }
    &:disabled {
      filter: hue-rotate(180deg);
    }
  }
  &-item-container {
    border: 1px solid #646079;
    color: white;
    background-color: var(--logo-purple);
    width: 100%;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    a {
      color: white;
      text-decoration: none;
      transition: color 200ms;
    }
    &:hover {
      background-color: var(--logo-blue);
      color: black;
      a {
        color: black;
      }
    }
  }
  &-hide {
    background-color: var(--logo-blue);
    color: black;
    a {
      color: black;
    }
    &:hover {
      background-color: white;
    }
  }
  &-pulse {
    position: relative;
    transition: background-color 350ms, color 350ms;
    overflow: hidden;
    &:hover::before,
    &:focus::before {
      content: "";
      position: absolute;
      right: 0;
      width: 20px;
      height: 105%;
      background: white;
      filter: blur(20.5px);
      transform: skew(-45deg) translate(0, -40%);
      animation: pillHover 4s infinite linear;
    }
    &:hover {
      background-color: var(--logo-blue) !important;
      color: black !important;
    }
  }
  &-pill-checkbox {
    // background-color: var(--logo-blue);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 200px;
    border: none;
    position: relative;
    overflow: hidden;
    transition: background-color 350ms, color 350ms;
    &:hover::before,
    &:focus::before {
      content: "";
      position: absolute;
      right: 0;
      width: 20px;
      height: 100%;
      background: white;
      filter: blur(20.5px);
      transform: skew(-45deg);
      animation: pillHover 3.5s infinite linear;
    }
    &.active {
      background-color: var(--logo-purple);
      color: white;
    }
    &.only {
      background-color: var(--logo-blue-comp);
    }
    &:disabled {
      color: black;
      background-color: var(--logo-purple-comp);
    }
    &:hover {
      background-color: var(--logo-blue);
      color: black !important;
    }
  }
  &-delete {
    padding: 0 0;
    background-color: black;
    color: white;
    border: none;
    &:hover {
      background-color: rgb(241, 54, 54);
      color: black;
      border: none;
      font-weight: bold;
    }
  }
}

.text {
  --tw-text-opacity: 1;
  &-black {
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  &-red {
    &-700 {
      color: rgba(185, 28, 28, var(--tw-text-opacity));
    }
    &-800 {
      color: rgba(153, 27, 27, var(--tw-text-opacity));
    }
    &-900 {
      color: rgba(127, 29, 29, var(--tw-text-opacity));
    }
  }
}

.max-w- {
  &inherit {
    max-width: inherit; // does not exist in tailwind
  }
  &xs {
    max-width: 20rem;
  }
  &sm {
    max-width: 24rem;
  }
  &md {
    max-width: 28rem;
  }
  &lg {
    max-width: 32rem;
  }
}

.floating-text {
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    text-overflow: unset;
    overflow: unset;
    animation: floatText 7.5s infinite linear;
  }
}
@keyframes floatText {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes pillHover {
  from {
    // transform: skew(-45deg) translateX(0ch);
    right: -15%;
  }
  18% {
    // transform: skew(-45deg) translateX(-45ch);
    right: 200%;
  }
  100% {
    // transform: skew(-45deg) translateX(-45ch);
    right: 200%;
  }
}

[id$="romaine-wrapper"] {
  width: min-content !important;
  min-width: 500px;
}

.blink {
  &-red {
    > div {
      animation: blink-animation-red 1.5s steps(2, jump-none) infinite;
    }
  }
}

@keyframes blink-animation-red {
  from {
    color: black;
  }
  to {
    background-color: red;
  }
}

.accent-color {
  &-gray-50 {
    accent-color: rgb(249, 250, 251);
  }
  &-gray-100 {
    accent-color: rgb(243, 244, 246);
  }
  &-gray-200 {
    accent-color: rgb(229, 231, 235);
  }
  &-gray-300 {
    accent-color: rgb(209, 213, 219);
  }
  &-gray-400 {
    accent-color: rgb(156, 163, 175);
  }
  &-gray-500 {
    accent-color: rgb(107, 114, 128);
  }
  &-gray-600 {
    accent-color: rgb(75, 85, 99);
  }
  &-gray-700 {
    accent-color: rgb(55, 65, 81);
  }
  &-gray-800 {
    accent-color: rgb(31, 41, 55);
  }
  &-gray-900 {
    accent-color: rgb(17, 24, 39);
  }
}
