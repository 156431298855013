.StagingBuilder-image {
  width: 100%;
}

.staging-builder-comments:not(:first-child) {
  border-top: #999 1px solid;
}
.staging-builder-comments {
  &__container {
    height: 400px;
  }
}
.comment-timestamp {
  color: #999;
}
.save-btn {
  width: 10ch;
  font-size: 18pt;
}
.MetaData-editor {
  width: 100%;
  height: 100%;
  grid-column: 1/3;
  h2 {
    margin-top: 20px;
  }
}

.staging-builder {
  &__stager-notes {
    grid-column: 3/4;
    grid-row: 1/2;
  }
}
.sb {
  &-dropdown {
    top: 37px;
    left: 0;
    width: 400px;
    transform: translateX(-2%);
    background-color: var(--logo-blue);
    border: 1px solid var(--logo-purple);
    border-radius: 8px;
    padding: 1rem;
    overflow: hidden;
    @media (max-width: 1024px) {
      top: 56px;
      left: unset;
    }
  }
  &-dropdown-item {
    position: relative;
    width: calc(var(--nav-size) * 0.8);
    justify-content: center;
    display: flex;
    a {
      color: black;
    }
    button {
      align-items: center;
      width: 100%;
      color: white;
      background-color: var(--logo-blue);
      border: none;
      height: 50px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      transition: background 400ms;
      padding: 0.5rem;
      line-height: 37px;
      div {
        transition: color 400ms;
        color: black;
      }
      &:hover {
        background-color: var(--logo-purple);
        div {
          color: #fff;
        }
        .sb-dropdown-item-icon {
          filter: brightness(1.2);
        }
      }
    }
    &-icon {
      color: white;
      width: 37px;
      height: 37px;
      background-color: var(--logo-purple);
      border-radius: 50%;
      padding: 5px;
      margin: 2px 10px 2px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: filter 300ms;
    }
  }
}
.draggable-inventory-container {
  background-color: var(--back-drop-light);
}

.search-header {
  box-shadow: lightgray 0px -1px 0px;
  border-bottom: 1px solid black;
  .mega-menu-wrapper {
    min-height: 390px;
    @media only screen and (max-width: 639px) {
      top: 8ch;
      border-top: var(--back-drop-light) thin solid;
      &.mobile-grid- {
        &left {
          grid-template-columns: 100% 0;
        }
        &right {
          grid-template-columns: 0 1fr;
        }
      }
    }
    @media only screen and (min-width: 640px) {
      grid-template-columns: 30ch 1fr;
      top: 4ch;
    }
  }
  li {
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    padding: 8px 4px 8px 0;
    &.main:first-of-type {
      @media (min-width: 640px) {
        text-align: left;
        flex-grow: 0.5;
      }
      padding-left: 5px;
    }
    &.main {
      text-align: center;
      flex-grow: 1;
    }
    &.main:last-of-type {
      @media (min-width: 640px) {
        text-align: right;
        flex-grow: 0.5;
      }
      padding-right: 5px;
    }
  }
  ul {
    li {
      padding: 0;
      transition: background-color 200ms;
    }
    .hover-gaurd {
      padding: 12px;
    }
    padding: 0 0 64px 0;
  }
  // li:hover {
  //   background-color: white;
  // }
  .link-list {
    li {
      cursor: initial;
      padding: 8px 0 0 12px;
    }
    li span:hover {
      cursor: pointer;
      text-decoration: underline;
      color: var(--link-blue);
    }
  }
}
