.ctx-menu {
  // --primary-color: whitesmoke;
  // --secondary-color: #ccc;

  --primary-color: var(--logo-blue);
  --secondary-color: var(--logo-purple);
  --primary-text: black;
  --hover-text: white;

  background-color: var(--primary-color);
  box-shadow: 0 0 5px #777;
  color: var(--primary-text);
  div {
    background-color: var(--primary-color);
    box-shadow: 0 0 5px #777;
  }
  & > ul {
    & > li {
      &:first-of-type {
        font-size: 1.1rem;
      }
      cursor: text;
      ul {
        > li {
          display: block;
          font-weight: 400;
          // font
          & > *:not(div) {
            display: block;
            padding: 4px 4px 4px 25px;
          }
          &:hover,
          a:hover,
          &:hover > a {
            text-decoration: none;
            background-color: var(--secondary-color);
            color: var(--hover-text);
            cursor: pointer;
          }
          * > ul {
            color: black;
          }
        }
      }
      &:not(:first-of-type) {
        border-top: thin solid var(--secondary-color);
      }
    }
  }
}
