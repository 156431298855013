// css reset
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}
a {
  display: inline-block;
  color: black;
  text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    align-items: center;
  }
}
p {
  margin: 0;
}
abbr {
  border-bottom: none;
}
tr,
td {
  margin: 0;
  padding: 0;
}
.text-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
// end reset
.mh-5 {
  margin-left: 5mm;
  margin-right: 5mm;
}
.white-bg {
  background-color: white;
}
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+128&display=swap");
// .printable-container {
//   overflow: hidden;
//   width: 7.5in;
//   .sheet-A {
//     height: 10.5in;
//     page-break-after: always;

.sr-tag {
  position: absolute;
  overflow: auto;
  width: 3.75in;
  height: 5.25in;
  border: black 1px solid;
  .img-container {
    display: grid;
    place-items: center;
    min-height: 2.75in;
    img.product {
      max-width: 80%;
      max-height: 2.75in;
      margin: 0 auto;
      display: block;
    }
    p {
      position: absolute;
      top: 0;
      right: 2mm;
      font-size: 1.1rem;
    }
  }
  .tag-info-container {
    width: 100%;
    margin: 0 auto;
    h1 {
      margin: 0 auto;
      text-align: center;
      font-size: 1.5rem;
      height: 2rem;
    }
    .writable-container {
      max-width: 95%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 5fr 1fr;
      &__writable-area {
        margin: 0 1%;
        height: 0.75in;
        border: 1px solid black;
      }
    }
    .tag-info {
      margin: 0.05in auto 0 auto;
      display: grid;
      grid-template-columns: 5fr 4fr;
      max-width: 95%;
      .barcode {
        width: 100%;
        height: 0.4in;
        font-family: "Libre Barcode 128";
        font-size: 2.5rem;
      }
      .below-barcode {
        display: grid;
        grid-template-columns: auto auto auto;
        h3 {
          margin: 0 auto;
        }
      }
      img {
        max-width: 100%;
      }
      h2 {
        margin: 0 auto;
      }

      .dollar-sign-target::before {
        content: "$";
        font-size: 65%;
        vertical-align: text-top;
      }
    }
  }
}
.mhfo-tag {
  position: relative;
  overflow: auto;
  width: 3.75in;
  height: 5.25in;
  border: black 1px solid;
  img.logo {
    height: 0.5in;
    margin: 0 auto 0.1in auto;
    display: block;
  }
  .product-img-container {
    height: 4.4cm;
    img.product {
      max-width: 80%;
      max-height: 4.4cm;
      margin: 0 auto;
      display: block;
    }
  }
  .colored-container {
    position: absolute;
    top: 2.6in;
    bottom: 0;
    left: 0.3cm;
    right: 0.3cm;
    margin: 0 auto;
    padding: 1cm;
    &.type-1 {
      background-color: rgb(232, 230, 222);
    }
    &.type-2 {
      background-color: rgb(178, 0, 0);
    }
    &.type-3 {
      background-color: rgb(0, 0, 255);
    }
    &.type-4 {
      background-color: rgb(0, 110, 54);
    }
    .mb-std {
      margin-bottom: 0.1in;
    }
    .item-name {
      font-size: 0.3in;
    }
    .sell-price {
      font-size: 0.6in;
      &__hundredths {
        margin-left: 0cm;
        font-size: 65%;
        vertical-align: text-top;
        text-decoration: underline;
      }
    }
    .sell-price::before {
      content: "$";
      margin-right: 0cm;
      font-size: 65%;
      vertical-align: text-top;
    }
    .tag-description {
      font-size: 0.15in;
      height: 0.4in;
    }
    .tri-split {
      position: absolute;
      left: 0.5cm;
      right: 0.5cm;
      bottom: 0.1in;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      .rental-info {
        font-size: 0.1in;
        text-align: right;
        align-self: flex-end;
        max-width: 8ch;
        margin: 0 auto 0 0;
        padding: 0.025in;
      }
      .compare-price-container {
        font-size: 0.1in;
        .compare-price::before {
          content: "$";
          margin-right: 0cm;
          font-size: 65%;
          vertical-align: text-top;
        }
        .compare-price {
          font-size: 0.25in;
          &__hundredths {
            margin-left: 0cm;
            font-size: 55%;
            vertical-align: text-top;
            text-decoration: underline;
          }
        }
      }
      .item-number {
        font-size: 0.1in;
        text-align: right;
        align-self: flex-end;
        max-width: 8ch;
        margin: 0 0 0 auto;
        padding: 0.025in;
      }
    }
  }
  &.collection-container {
    h1 {
      margin-bottom: 0.3cm;
    }
    table {
      width: 100%;
      text-align: left;
      td {
        padding-bottom: 0.15cm;
      }
    }
  }
}

//   }
// }
