.inventory-table {
  --number-columns: 7;
  // the 12 px is because of the scroll bar
  width: calc(100% - 12px);
  table-layout: fixed;
  border-collapse: collapse;
}

.inventory-table tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: calc(95vh - 21ch);
  position: absolute;
  bottom: 0;
  border: none;
}

.inventory-table thead tr {
  display: table-row;
  margin: 0 10px 0 0;
}

.inventory-table th,
.inventory-table td {
  padding: 5px;
  text-align: center;
  width: calc((100vw - 260px - 1rem) / 2 / var(--number-columns));
}
.inventory-row,
.inventory-image {
  min-height: 80px;
  max-height: 80px;
  max-width: 80px;
}
.inventory-image {
  object-fit: contain;
  margin: auto;
}
.inventory-row {
  height: 80px;
}
.inventory-row {
  background-color: #fff;
  transition: background-color 200ms;
  cursor: pointer;
}
.inventory-row:hover {
  background-color: #ccc;
}
.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}
.inventory-input {
  max-width: 100%;
  min-width: 0;
  padding: 0;
  margin: 1px 1px;
  font-size: 15px;
}
