.default-input {
  margin: 2px 0px;
}
.form-error-text {
  color: red;
}

.p-label {
  &__checkbox {
    margin: 0 0 0 8px;
  }
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.multi-select-container {
  input {
    box-shadow: none;
  }
}
.max-width-4em {
  max-width: 4em;
}
.height-3em {
  height: 3em;
}
