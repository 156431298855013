.item-container {
  &__header {
    &__price {
      color: white;
    }
  }
  &__item {
    place-items: center;
    padding-bottom: 40px;
    .filter-item {
      bottom: 2.8em;
      z-index: 1;
      padding-top: calc(100% - 2.8em);
      filter: opacity(0.45);
      &.pink {
        background-color: pink;
      }
      &.yellow {
        background-color: yellow;
      }
    }
    .markup-menu {
      background-color: white;
      transition: transform 200ms;
      transform: rotateY(90deg);
      &.active {
        transform: rotateY(0deg);
      }
    }
    &__info {
      position: absolute;
      bottom: 0;
      width: 100%;
      &__price::before {
        content: "$";
        font-size: 65%;
        vertical-align: text-top;
      }
    }
    &__image {
      float: left;
      padding-top: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 100%;
      width: 100%;
    }
  }
}
.ribbon-wrapper {
  overflow: hidden;
  top: 0;
  z-index: 2;
  pointer-events: none;
  .ribbon {
    top: 15px;
    left: -25%;
    position: absolute;
    padding: 2px 0;
    transform: rotate(-30deg);
    background-color: yellow;
    span {
      color: #000;
      text-decoration: none;
      text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
      text-align: center;
      width: 200px;
      line-height: 20px;
      display: inline-block;
      padding: 2px 0;
      font-size: 0.8em;
    }
  }
}

.DeleteFormButton {
  background-color: var(--logo-purple);
}
.DeleteFormButton:hover {
  background-color: var(--logo-blue);
  color: black;
}
.containerItem-select {
  color: rgb(0, 96, 223);
  width: 5ch;
  height: 1.4em;
  border: 1px solid #999;
  font-size: 14px;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: 1.4em;
  border: none;
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
