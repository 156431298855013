.proposal-row {
  // margin: 8px;
  outline: 1px solid lightgrey;
  border-radius: 2px;
  transition: background-color 200ms;
  cursor: pointer;
  height: 4rem;
}
.proposal-row:hover {
  background-color: #ddd !important;
}

.proposal-header {
  border-radius: 2px;
  outline: 1px solid lightgrey;
  cursor: pointer;
}
